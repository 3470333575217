/**
* my theme styles
*/
/* @import url("app.css"); */

/* rtl-fonts */
@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(/fonts/yekanweblight.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* rtl-fonts */
@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(/fonts/yekanwebregular.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* rtl-fonts */
@font-face {
    font-family: 'yekan';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(/fonts/yekanwebbold.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body{font-family:  'Nunito', 'yekan', sans-serif;}

.panel_body{
    background-image: url("/img/bg/0.webp");
    background-color: #f2f6fc;
    background-size: 300px;
}

.background-fixed{background-attachment: fixed;}

a{text-decoration: none;}

a.link_1{color: #333; text-decoration: none;}

a.link_1:hover{color: #999;}

a.offcanvas_link{
    color: #333;
    text-decoration: none;
}
a.offcanvas_link:hover{
    background-color:#f2f6fc;
}

svg{
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle;
}

.bi-1 {
    display: inline-block;
    vertical-align: -0.125em;
}

.bi-2 {
    display: inline-block;
    vertical-align: -0.225em;
}

.bi-3 {
    display: inline-block;
    vertical-align: -0.325em;
}

.fs-7 {
    font-size: 0.85rem !important;
}

.fs-10 {
    font-size: 0.65rem !important;
}


.m_w_60{min-width: 60px}
.m_w_70{min-width: 70px}
.m_w_80{min-width: 80px}

.text-tiny{font-size: 0.7rem !important;}
.text-small{font-size: 0.85rem !important;}
/* .text-default{font-size: 1rem !important;} */
.text-big{font-size: 1.2rem !important;}
.text-huge{font-size: 1.4rem !important;}

.logo{width: auto; height: 35px;}
.logo_2{width: auto; height: 50px;}
.img_60_60{ width: 60px; height: 60px;}
.img_75_75{ width: 75px; height: 75px;}
.img_100_100{ width: 100px; height: 100px;}

.svg-border-rounded svg{bottom:0;left:0;height:1.41rem;width:100%; padding: 0px !important; margin: 0 !important;}
/* .svg-border-rounded svg{bottom:0;left:0;height:1.3rem;width:100%; padding: 0px !important; margin: 0 !important;}@media (min-width: 576px){.svg-border-rounded svg{height:1.3rem}}@media (min-width: 768px){.svg-border-rounded svg{height:1.7rem}}@media (min-width: 992px){.svg-border-rounded svg{height:2rem}}@media (min-width: 1200px){.svg-border-rounded svg{height:2rem}} */

.bg_gradient_1{
    background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
}

.bg_gradient_2{
    background-image: linear-gradient( 135deg, #3C8CE7 10%, #00EAFF 100%);
}

.bg_gradient_telegram{
    background-image: linear-gradient( 135deg, #229ED9 10%, #4cbbf7 100%);
}

.bg-default{
    background-color: #f2f6fc;
}

.bg-default-2{
    background-color: #d7e3f3;
}

.bg-gray-light{
    background-color: #eff3f6;
}

.bg-gray-dark{
    background-color: var(--bs-gray-dark);
}

.bg-blue-1{background-color: #179cde;}
.bg-green-1{background-color: #00c298;}

.bg-indigo{background-color: var(--bs-indigo);}
.bg-purple{background-color: var(--bs-purple);}

.bg-x1 {
    background: linear-gradient(-45deg,  #e73c7e, #ffbe0d, #23d5ab);
	background-size: 400% 400%;
	animation: gradient-bg 10s ease infinite;
    transition: 0.5s;
    color: white;
}

.bg-featured{
    background: rgb(255,240,195);
    background: linear-gradient(180deg, rgb(253, 237, 188) 0%, rgba(255,247,223,1) 15%, rgba(255,255,255,1) 70%);
}

.text-featured{writing-mode: vertical-rl; font-size: 0.6rem !important;}
.text-vertical{writing-mode: vertical-rl;}
.text-shadow{text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);}
.drop-shadow{filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));}


.text-blue-dark{color: #0047b1;}
.text-indigo{color: var(--bs-indigo);}
.text-purple{color: var(--bs-purple);}
.text-pink{color: var(--bs-pink);}
.text-teal{color: var(--bs-teal);}
.text-orange{color: var(--bs-orange);}

.dropdown:hover .dropdown-menu{
    display: block;
}

.dropdown-menu{
    border-radius: 0 0 0.4rem 0.4rem;
    border: none;
    /* margin-top: 4px; */
}

.font_ltr{
    font-family: 'Nunito', sans-serif;
}
.font_rtl{
    font-family: 'Noto Sans Arabic', Tahoma;
}
.font_tahoma{
    font-family: Tahoma;
}
.navbar{ min-height: 55px;}

.min_h_110{min-height: 110px}

.glass{
    background-color: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.glass-2{
    background-color: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.glass-3{
    background-color: rgba(211, 235, 255, 0.5) !important;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

/* @-moz-document url-prefix() {
    .glass{
        background-color: rgba(255, 255, 255) !important;
    }
    .glass-2{
        background-color: rgba(255, 255, 255, 0.8) !important;
    }
    .glass-3{
        background-color: rgba(211, 235, 255, 0.8) !important;
    }
} */


.offcanvas{
    border: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
    max-width: 73%;
    background-color: #fff;
    transition: transform 0.4s ease-in-out;
}

.navbar_space{
    padding-top: 65px;
}

.my_sticky_top{top: 5.05rem;}

.main_icon{ max-width: 180px; height: auto;}
.main_icon_2{ max-width: 130px; height: auto;}
.main_icon_3{ max-width: 80px; height: auto;}

.rounded-4 {
    border-radius: 0.5rem !important;
}

.rounded-5 {
border-radius: 0.75rem !important;
}

.border-bottom-radius-inherit{border-bottom-right-radius: inherit; border-bottom-left-radius: inherit;}

.hover_shadow:hover{
    box-shadow: 0 0.125rem 0.55rem rgba(0, 0, 0, 0.2) !important;
}
.hover_shadow:hover * h2{ color: var(--bs-pink) !important;}
.hover_shadow:hover * h3{ color: var(--bs-pink) !important;}


.btn_no_outline:focus{box-shadow: none;}

.btn-x{color:#fff;background-color:teal;}

/* .btn-x:hover:hover{color:#fff;background-color:#004d4d;}
.btn-x:focus:focus,.btn-primary.focus{box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)}
.btn-x.disabled,.btn-primary:disabled{color:#fff;background-color:teal;}
.btn-x:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle{color:#fff;background-color:#00b3b3;}
.btn-x:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)} */

.btn-x1 {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
    /* background-size: 200% auto; */
	animation: gradient 10s ease infinite;
    text-align: center;
    transition: 0.5s;
    color: white;
    /* box-shadow: 0 0 20px #eee; */
  }

  .btn-x1:hover {
    background-position: right center; /* change the direction of the change here */
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }


/* .hover_1{} */
.hover_1:hover{background-color: #f2f6fc;}
.hover_2:hover{background-color: #e6f0fd;}
.active_1{background-color: #e9e9e9 !important;}
.active_2{background-color: #c0dbff !important;}
.active_3{background-color: #f2f3f5 !important;  color: #1e2125;}

.cursor-pointer{ cursor: pointer;}

.position-fixed{position: fixed;}

.my_overflow_auto {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.my_overflow_auto::-webkit-scrollbar {
    display: none;
}

.my_breadcrumb {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.my_breadcrumb::-webkit-scrollbar {
    display: none;
}


/* #cookieBanner{
    display: block;
    margin: 30px;
    transition: all 0.5s ease-in;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
} */

#cookieBanner{
    position: fixed;
    width: max-content;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    transition: all 2s ease;
    left: 2em;
    right: 2em;
}

.hide_cookieBanner{
    visibility: hidden;
    bottom: -10em;
}

.show_cookieBanner{
    visibility: visible;
    bottom: 2em;
}

.scale-up-bl {
	-webkit-animation: scale-up-bl 0.8s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0.5s backwards;
	        animation: scale-up-bl 0.8s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0.5s backwards;
}

.flip-scale-2-ver-right {
	-webkit-animation: flip-scale-2-ver-right 0.6s linear 3s both;
	        animation: flip-scale-2-ver-right 0.6s linear 3s both;
}

/***** keyframes v *****/

 @-webkit-keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
}
@keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
}

@-webkit-keyframes flip-scale-2-ver-right {
    0% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    50% {
      -webkit-transform: translateX(50%) rotateY(-90deg) scale(2);
              transform: translateX(50%) rotateY(-90deg) scale(2);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    100% {
      -webkit-transform: translateX(100%) rotateY(-180deg) scale(1);
              transform: translateX(100%) rotateY(-180deg) scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @keyframes flip-scale-2-ver-right {
    0% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    50% {
      -webkit-transform: translateX(50%) rotateY(-90deg) scale(2);
              transform: translateX(50%) rotateY(-90deg) scale(2);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    100% {
      -webkit-transform: translateX(100%) rotateY(-180deg) scale(1);
              transform: translateX(100%) rotateY(-180deg) scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }

@keyframes gradient-bg {
	0% {background-position: 0% 50%;}
	50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/***** keyframes ^ *****/

@media (max-width: 768px) {
    #cookieBanner{
        width: 100%;
        border-radius: 0;
        left: 0em;
        right: 0em;
    }
    .show_cookieBanner{
        bottom: 0em;
    }
 }

/*# sourceMappingURL=theme.css.map */
